import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {IpLocationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.shared.RequestInfoController
 */

@Injectable()
@ResourceParams({})
export class RequestInfoResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/request-info/location',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRequestLocation: IResourceMethodObservableStrict<null, null, null, IpLocationData> | undefined
    getRequestLocation(): Promise<IpLocationData> {
        if (!this._getRequestLocation) throw new Error("resource has not been properly initialized")
        return this._getRequestLocation(null, null, null).toPromise()
    }

}