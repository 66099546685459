import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {AppComponent} from "./app.component";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppRoutingModule} from "./app-routing.module";
import {TalentPageModule} from "./general/talent-page/talent-page.module";
import {CompanyPageModule} from "./general/company-page/company-page.module";
import {AboutPageModule} from "./general/about-page/about-page.module";
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {environment} from "../environments/environment";
import {PublicBundleResource} from "../generated/hid/resources";
import {ResourceModule} from "@ngx-resource/handler-ngx-http";
import {HttpClientModule} from "@angular/common/http";
import {CareerGuideModule} from "./general/career-guide/career-guide.module";
import {UtilityModule} from "./utility/utility.module";
import {CaseStudiesModule} from "./general/case-studies/case-studies.module";
import {LandingPagesModule} from "./general/landing-pages/landing-pages.module";
import {DemoBookingModule} from "./general/demo-booking/demo-booking.module";
import {WebinarModule} from "./general/webinar/webinar.module";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import localeDe from '@angular/common/locales/de';
import {GdprConsentModule} from "./utility/services/gdprconsent/gdpr-consent.module";
import {LegalModule} from "./general/legal/legal.module";
import {PositionDetailsModule} from "./general/position-details/position-details.module";
import {PublicEmploymentPositionResource, PublicLandingPageResource} from "../generated/resources";
import {NgxPiwikProModule, NgxPiwikProRouterModule} from "@piwikpro/ngx-piwik-pro";
import {BugsnagErrorHandler} from "@bugsnag/plugin-angular";
import Bugsnag from "@bugsnag/js";


registerLocaleData(localeDe);
export function errorHandlerFactory() {
  let releaseStage = 'production';
  if (!environment.production) {
    return new ErrorHandler()
  }
  return new BugsnagErrorHandler(Bugsnag.start({
    apiKey: '6c94e0f2796f4e05ea80bf4c03671f56',
    releaseStage: releaseStage
  }))
}


export const APP_DATE_FORMATS =
  {
    parse: {
      dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
    },
    display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'numeric'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'},
    }
  };

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    UtilityModule,
    ResourceModule.forRoot(),
    NgxPiwikProModule.forRoot(environment.piwikContainerId, environment.piwikContainerUrl),
    NgxPiwikProRouterModule,
    //Created Modules
    GdprConsentModule,
    TalentPageModule,
    CompanyPageModule,
    AboutPageModule,
    CareerGuideModule,
    CaseStudiesModule,
    AppRoutingModule,
    LandingPagesModule,
    DemoBookingModule,
    WebinarModule,
    PositionDetailsModule,
    LegalModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: 'env', // you can also use InjectionToken
      useValue: environment
    },
    {provide: LOCALE_ID, useValue: 'de-DE'},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},

    PublicBundleResource,
    PublicEmploymentPositionResource,
      PublicLandingPageResource,

    provideAnimationsAsync()
  ]
})
export class AppModule { }
