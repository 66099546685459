import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, PositionPrivacyDisclaimerData, TalentPositionPreviewData, PublicEmploymentPositionDetailsData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentEmploymentPositionController
 */

@Injectable()
@ResourceParams({})
export class TalentEmploymentPositionResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/positions/active/count',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getActivePositionCount: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number>> | undefined
    getActivePositionCount(): Promise<number> {
        if (!this._getActivePositionCount) throw new Error("resource has not been properly initialized")
        return this._getActivePositionCount(null, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/positions/{id}/company/logo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyLogoByPosition: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getCompanyLogoByPosition(id: number): Promise<Blob> {
        if (!this._getCompanyLogoByPosition) throw new Error("resource has not been properly initialized")
        return this._getCompanyLogoByPosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/companies/{companyId}/positions/all',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEmploymentPositionsForCompany: IResourceMethodObservableStrict<null, null, {companyId: number}, TalentPositionPreviewData[]> | undefined
    getEmploymentPositionsForCompany(companyId: number): Promise<TalentPositionPreviewData[]> {
        if (!this._getEmploymentPositionsForCompany) throw new Error("resource has not been properly initialized")
        return this._getEmploymentPositionsForCompany(null, null, {companyId: companyId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/positions/{id}/privacy/disclaimer',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getLatestPrivacyDisclaimerInformation: IResourceMethodObservableStrict<null, null, {id: number}, PositionPrivacyDisclaimerData> | undefined
    getLatestPrivacyDisclaimerInformation(id: number): Promise<PositionPrivacyDisclaimerData> {
        if (!this._getLatestPrivacyDisclaimerInformation) throw new Error("resource has not been properly initialized")
        return this._getLatestPrivacyDisclaimerInformation(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/positions/{id}/personalizedLocation',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPersonalizedLocationString: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<string>> | undefined
    getPersonalizedLocationString(id: number): Promise<string> {
        if (!this._getPersonalizedLocationString) throw new Error("resource has not been properly initialized")
        return this._getPersonalizedLocationString(null, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/positions/{employmentPositionId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSingleEmploymentPositionById: IResourceMethodObservableStrict<null, null, {employmentPositionId: number}, PublicEmploymentPositionDetailsData> | undefined
    getSingleEmploymentPositionById(employmentPositionId: number): Promise<PublicEmploymentPositionDetailsData> {
        if (!this._getSingleEmploymentPositionById) throw new Error("resource has not been properly initialized")
        return this._getSingleEmploymentPositionById(null, null, {employmentPositionId: employmentPositionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/positions/{id}/active',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _isPositionActive: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<boolean>> | undefined
    isPositionActive(id: number): Promise<boolean> {
        if (!this._isPositionActive) throw new Error("resource has not been properly initialized")
        return this._isPositionActive(null, null, {id: id}).toPromise().then((result) => result.value)
    }

}