import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, MinimalApplicantsData, ApplicationRejectedData, ApplicationData, PagedData, ApplicationCommentData, RequestedApplicationDocumentsData, CompanyHiringInfoUpdateData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyApplicationController
 */

@Injectable()
@ResourceParams({})
export class CompanyApplicationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/applications/{id}/comment',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _addCommentToApplication: IResourceMethodObservableStrict<null, {commentText: string}, {id: number}, ApplicationCommentData> | undefined
    addCommentToApplication(id: number, requestParams: {commentText: string}): Promise<ApplicationCommentData> {
        if (!this._addCommentToApplication) throw new Error("resource has not been properly initialized")
        return this._addCommentToApplication(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/applications/talent/{id}/exists',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _applicationsExistForTalent: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<boolean>> | undefined
    applicationsExistForTalent(id: number): Promise<boolean> {
        if (!this._applicationsExistForTalent) throw new Error("resource has not been properly initialized")
        return this._applicationsExistForTalent(null, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company_new/applications',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllApplications: IResourceMethodObservableStrict<null, {states: ('Draft' | 'Sent' | 'Denied' | 'Withdrawn' | 'Granted' | 'Failed' | 'Interesting' | 'AppProcessEnter' | 'ContractSent' | 'Hired')[], q?: string, positionId?: number, page: number, pageSize: number}, null, PagedData<ApplicationData>> | undefined
    getAllApplications(requestParams: {states: ('Draft' | 'Sent' | 'Denied' | 'Withdrawn' | 'Granted' | 'Failed' | 'Interesting' | 'AppProcessEnter' | 'ContractSent' | 'Hired')[], q?: string, positionId?: number, page: number, pageSize: number}): Promise<PagedData<ApplicationData>> {
        if (!this._getAllApplications) throw new Error("resource has not been properly initialized")
        return this._getAllApplications(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/applications/applicants',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllCompanyApplicants: IResourceMethodObservableStrict<null, {query?: string, showAssigned: boolean, page: number, pageSize: number}, null, PagedData<MinimalApplicantsData>> | undefined
    getAllCompanyApplicants(requestParams: {query?: string, showAssigned: boolean, page: number, pageSize: number}): Promise<PagedData<MinimalApplicantsData>> {
        if (!this._getAllCompanyApplicants) throw new Error("resource has not been properly initialized")
        return this._getAllCompanyApplicants(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/applications/positions/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicationsForEmploymentPositionByState: IResourceMethodObservableStrict<null, {states: ('Draft' | 'Sent' | 'Denied' | 'Withdrawn' | 'Granted' | 'Failed' | 'Interesting' | 'AppProcessEnter' | 'ContractSent' | 'Hired')[], page: number, pageSize: number}, {id: number}, PagedData<ApplicationData>> | undefined
    getApplicationsForEmploymentPositionByState(id: number, requestParams: {states: ('Draft' | 'Sent' | 'Denied' | 'Withdrawn' | 'Granted' | 'Failed' | 'Interesting' | 'AppProcessEnter' | 'ContractSent' | 'Hired')[], page: number, pageSize: number}): Promise<PagedData<ApplicationData>> {
        if (!this._getApplicationsForEmploymentPositionByState) throw new Error("resource has not been properly initialized")
        return this._getApplicationsForEmploymentPositionByState(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/applications/{id}/documents',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRequestedAdditionalDocumentsForTalentApplication: IResourceMethodObservableStrict<null, null, {id: number}, RequestedApplicationDocumentsData[]> | undefined
    getRequestedAdditionalDocumentsForTalentApplication(id: number): Promise<RequestedApplicationDocumentsData[]> {
        if (!this._getRequestedAdditionalDocumentsForTalentApplication) throw new Error("resource has not been properly initialized")
        return this._getRequestedAdditionalDocumentsForTalentApplication(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company_new/applications/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSingleApplication: IResourceMethodObservableStrict<null, null, {id: number}, ApplicationData> | undefined
    getSingleApplication(id: number): Promise<ApplicationData> {
        if (!this._getSingleApplication) throw new Error("resource has not been properly initialized")
        return this._getSingleApplication(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/applications/seen',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _markApplicationsAsSeen: IResourceMethodObservableStrict<JsonWrappedValue<number[]>, null, null, null> | undefined
    markApplicationsAsSeen(requestBody: JsonWrappedValue<number[]>): Promise<null> {
        if (!this._markApplicationsAsSeen) throw new Error("resource has not been properly initialized")
        return this._markApplicationsAsSeen(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/applications/{id}/documents',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _requestAdditionalDocumentsForTalentApplication: IResourceMethodObservableStrict<string[], null, {id: number}, null> | undefined
    requestAdditionalDocumentsForTalentApplication(requestBody: string[], id: number): Promise<null> {
        if (!this._requestAdditionalDocumentsForTalentApplication) throw new Error("resource has not been properly initialized")
        return this._requestAdditionalDocumentsForTalentApplication(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/company/applications/{talentId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _sortOutTalent: IResourceMethodObservableStrict<ApplicationRejectedData, null, {talentId: number}, null> | undefined
    sortOutTalent(requestBody: ApplicationRejectedData, talentId: number): Promise<null> {
        if (!this._sortOutTalent) throw new Error("resource has not been properly initialized")
        return this._sortOutTalent(requestBody, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/applications/{id}/state',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateApplicationState: IResourceMethodObservableStrict<null, {state: ('Draft' | 'Sent' | 'Denied' | 'Withdrawn' | 'Granted' | 'Failed' | 'Interesting' | 'AppProcessEnter' | 'ContractSent' | 'Hired')}, {id: number}, null> | undefined
    updateApplicationState(id: number, requestParams: {state: ('Draft' | 'Sent' | 'Denied' | 'Withdrawn' | 'Granted' | 'Failed' | 'Interesting' | 'AppProcessEnter' | 'ContractSent' | 'Hired')}): Promise<null> {
        if (!this._updateApplicationState) throw new Error("resource has not been properly initialized")
        return this._updateApplicationState(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/applications/{id}/updateHiringInformation',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateHiringInformationForApplication: IResourceMethodObservableStrict<CompanyHiringInfoUpdateData, null, {id: number}, CompanyHiringInfoUpdateData> | undefined
    updateHiringInformationForApplication(requestBody: CompanyHiringInfoUpdateData, id: number): Promise<CompanyHiringInfoUpdateData> {
        if (!this._updateHiringInformationForApplication) throw new Error("resource has not been properly initialized")
        return this._updateHiringInformationForApplication(requestBody, null, {id: id}).toPromise()
    }

}