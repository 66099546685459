<div class="flex column gap-lg">
  <div class="header">
    <img [src]="guideBanner" alt="">
    <div class="blue-overlay">
      <h1 class="text-shadow">{{careerGuideSplit.articleTitle}}</h1>
    </div>
  </div>

  <section class="flex row space-around width-100 content-container">
    <div class="flex column cap-width">
      <div *ngFor="let content of careerGuideSplit?.articleContents"
           [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile(), 'padding-horizontal': mediaService.isMobile()}">

        <h4 *ngIf="content?.type == 'HeaderText'" class="header-text margin-bottom-lg" [innerHTML]="content.content | safeHtml"></h4>

        <div *ngIf="content.type == 'TableOfContents'" class="margin-bottom-lg">
          <h1 class="medium-section-header margin-bottom">Was dich erwartet</h1>
          <div class="flex column gap-lg">
            <div *ngFor="let headline of tableOfContents; let index = index" class="flex row start-center gap">
              <div class="index-number">
                {{index + 1}}
              </div>
              <h3 class="color-teal">{{ headline }}</h3>
            </div>
          </div>
        </div>

        <div *ngIf="content.type == 'CustomContent'" class="margin-bottom-lg">
          <h2>{{content?.headline}}</h2>
          <div class="content" [innerHTML]="content?.content | safeHtml"></div>
        </div>

        <div *ngIf="content.type == 'OnboardingCatcher' && mediaService.isTablet()" class="margin-bottom-lg">
          <app-career-guide-onboarding-catcher [content]="careerGuideSplit.onboardingCatcherText"
                                               [splitId]="careerGuideSplit.id"
                                               [buttonText]="careerGuideSplit.onboardingCatcherButtonText">
          </app-career-guide-onboarding-catcher>
        </div>

      </div>
    </div>

    <div *ngIf="!mediaService.isTablet()" class="catcher-side">
      <app-career-guide-onboarding-catcher [content]="careerGuideSplit.onboardingCatcherText"
                                           [splitId]="careerGuideSplit.id"
                                           [buttonText]="careerGuideSplit.onboardingCatcherButtonText">
      </app-career-guide-onboarding-catcher>
    </div>

  </section>

  <section class="flex padding-lg column center width-100">
    <h1 class="big-section-header center-text"> Mehr Guides</h1>
    <div class="flex padding-vertical row cap-width guides horizontal-scrollable-container width-100"
         [ngClass]="{'start': mediaService.isMobile(), 'center': !mediaService.isMobile(), 'gap-lg': !mediaService.isMobile()}">
      <div class="padding" *ngFor="let guide of otherGuides; let i = index" [ngClass]="{'delay-0-2s': i == 1, 'delay-0-4s': i == 2}">
        <app-career-guide-card [careerGuide]="guide"></app-career-guide-card>
      </div>
    </div>
    <button class="accent-button margin-top" (click)="routeToAllGuides()">Alle Guides</button>
  </section>

  <div class="bottom-container flex row center"
       *ngIf="mediaService.isMobile()">

    <button class="primary-button width-50"
            (click)="routeToTalentAgent()">
      Zu den Jobs
    </button>
  </div>


</div>
