import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, ConsultantProfileDisplayData, TalentCoachRequestDistributionData, InputFieldPrepopulationData, TalentCreationData, PositionQuestionData, TalentOnboardingAvailablePositionsData, TalentPositionQuestionAnswerData, LabelData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.onboarding.TalentOnboardingController
 */

@Injectable()
@ResourceParams({})
export class TalentOnboardingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/talent/applications/{applicationId}/answer/create',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _createPositionAnswersForApplication: IResourceMethodObservableStrict<TalentPositionQuestionAnswerData[], null, {applicationId: number}, null> | undefined
    createPositionAnswersForApplication(requestBody: TalentPositionQuestionAnswerData[], applicationId: number): Promise<null> {
        if (!this._createPositionAnswersForApplication) throw new Error("resource has not been properly initialized")
        return this._createPositionAnswersForApplication(requestBody, null, {applicationId: applicationId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/finalize',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _finalizeTalentOnboardingForTalent: IResourceMethodObservableStrict<null, null, null, null> | undefined
    finalizeTalentOnboardingForTalent(): Promise<null> {
        if (!this._finalizeTalentOnboardingForTalent) throw new Error("resource has not been properly initialized")
        return this._finalizeTalentOnboardingForTalent(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/onboarding/academicTitle',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAcademicTitles: IResourceMethodObservableStrict<null, null, null, string[]> | undefined
    getAcademicTitles(): Promise<string[]> {
        if (!this._getAcademicTitles) throw new Error("resource has not been properly initialized")
        return this._getAcademicTitles(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/onboarding/positions/{id}/questions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getActivePositionQuestions: IResourceMethodObservableStrict<null, null, {id: number}, PositionQuestionData[]> | undefined
    getActivePositionQuestions(id: number): Promise<PositionQuestionData[]> {
        if (!this._getActivePositionQuestions) throw new Error("resource has not been properly initialized")
        return this._getActivePositionQuestions(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/onboarding/availablePositions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAvailablePositionsCountForSubject: IResourceMethodObservableStrict<null, {subjectId: number}, null, TalentOnboardingAvailablePositionsData> | undefined
    getAvailablePositionsCountForSubject(requestParams: {subjectId: number}): Promise<TalentOnboardingAvailablePositionsData> {
        if (!this._getAvailablePositionsCountForSubject) throw new Error("resource has not been properly initialized")
        return this._getAvailablePositionsCountForSubject(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/onboarding/coachInfo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCoachInfo: IResourceMethodObservableStrict<null, {token: string}, null, ConsultantProfileDisplayData> | undefined
    getCoachInfo(requestParams: {token: string}): Promise<ConsultantProfileDisplayData> {
        if (!this._getCoachInfo) throw new Error("resource has not been properly initialized")
        return this._getCoachInfo(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/onboarding/coach/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCoachProfilePicture: IResourceMethodObservableStrict<null, {token: string}, null, Blob> | undefined
    getCoachProfilePicture(requestParams: {token: string}): Promise<Blob> {
        if (!this._getCoachProfilePicture) throw new Error("resource has not been properly initialized")
        return this._getCoachProfilePicture(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/onboarding/matching/timestamp',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMatchingTimestamp: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number>> | undefined
    getMatchingTimestamp(): Promise<number> {
        if (!this._getMatchingTimestamp) throw new Error("resource has not been properly initialized")
        return this._getMatchingTimestamp(null, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/onboarding/professionFields',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRelevantProfessionFieldsForSubject: IResourceMethodObservableStrict<null, {dkzsubjectId?: number}, null, LabelData[]> | undefined
    getRelevantProfessionFieldsForSubject(requestParams?: {dkzsubjectId?: number}): Promise<LabelData[]> {
        if (!this._getRelevantProfessionFieldsForSubject) throw new Error("resource has not been properly initialized")
        return this._getRelevantProfessionFieldsForSubject(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/noCv',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _reportTalentNoCvOnboarding: IResourceMethodObservableStrict<null, null, null, null> | undefined
    reportTalentNoCvOnboarding(): Promise<null> {
        if (!this._reportTalentNoCvOnboarding) throw new Error("resource has not been properly initialized")
        return this._reportTalentNoCvOnboarding(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/sendCvSkipMail',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _reportTalentSkipedCvUploadOnboarding: IResourceMethodObservableStrict<null, null, null, null> | undefined
    reportTalentSkipedCvUploadOnboarding(): Promise<null> {
        if (!this._reportTalentSkipedCvUploadOnboarding) throw new Error("resource has not been properly initialized")
        return this._reportTalentSkipedCvUploadOnboarding(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/talentAgent',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _requestCoach: IResourceMethodObservableStrict<number[], null, null, null> | undefined
    requestCoach(requestBody: number[]): Promise<null> {
        if (!this._requestCoach) throw new Error("resource has not been properly initialized")
        return this._requestCoach(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/onboarding/s2/coach/distribution',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateDistributionCriteriaForCoachRequest: IResourceMethodObservableStrict<TalentCoachRequestDistributionData, null, null, null> | undefined
    updateDistributionCriteriaForCoachRequest(requestBody: TalentCoachRequestDistributionData): Promise<null> {
        if (!this._updateDistributionCriteriaForCoachRequest) throw new Error("resource has not been properly initialized")
        return this._updateDistributionCriteriaForCoachRequest(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/account',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateTalentAccountAndFinishOnboarding: IResourceMethodObservableStrict<TalentCreationData, null, null, null> | undefined
    updateTalentAccountAndFinishOnboarding(requestBody: TalentCreationData): Promise<null> {
        if (!this._updateTalentAccountAndFinishOnboarding) throw new Error("resource has not been properly initialized")
        return this._updateTalentAccountAndFinishOnboarding(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/CurriculumVitae/parse',
        endpoint: 'com.uniwunder.talentagent.main',
        requestBodyType: ResourceRequestBodyType.FORM_DATA
    })
    private _uploadAndParseCVForOnboarding: IResourceMethodObservableStrict<{file: File, fileName: string}, null, null, InputFieldPrepopulationData> | undefined
    uploadAndParseCVForOnboarding(requestBody: {file: File, fileName: string}): Promise<InputFieldPrepopulationData> {
        if (!this._uploadAndParseCVForOnboarding) throw new Error("resource has not been properly initialized")
        return this._uploadAndParseCVForOnboarding(requestBody, null, null).toPromise()
    }

}