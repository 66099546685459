import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {EmploymentPositionLandingPageData, JsonWrappedValue, PublicCompanyProfileData, PublicTalentTalentAgentLandingPageData, PagedData, LabelData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.shared.PublicController
 */

@Injectable()
@ResourceParams({})
export class PublicResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/company/{id}/companyProfile/contactImage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyContactImage: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getCompanyContactImage(id: number): Promise<Blob> {
        if (!this._getCompanyContactImage) throw new Error("resource has not been properly initialized")
        return this._getCompanyContactImage(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/companyLogo/{positionId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyLogo: IResourceMethodObservableStrict<null, null, {positionId: number}, Blob> | undefined
    getCompanyLogo(positionId: number): Promise<Blob> {
        if (!this._getCompanyLogo) throw new Error("resource has not been properly initialized")
        return this._getCompanyLogo(null, null, {positionId: positionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/company/{companyId}/companyLogo',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyLogoForCompany: IResourceMethodObservableStrict<null, null, {companyId: number}, Blob> | undefined
    getCompanyLogoForCompany(companyId: number): Promise<Blob> {
        if (!this._getCompanyLogoForCompany) throw new Error("resource has not been properly initialized")
        return this._getCompanyLogoForCompany(null, null, {companyId: companyId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/company/{id}/companyProfile',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyProfileByCompanyId: IResourceMethodObservableStrict<null, null, {id: number}, PublicCompanyProfileData> | undefined
    getCompanyProfileByCompanyId(id: number): Promise<PublicCompanyProfileData> {
        if (!this._getCompanyProfileByCompanyId) throw new Error("resource has not been properly initialized")
        return this._getCompanyProfileByCompanyId(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/company/{id}/companyProfile/galleryImages/{imageId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyProfileGalleryImage: IResourceMethodObservableStrict<null, null, {id: number, imageId: number}, Blob> | undefined
    getCompanyProfileGalleryImage(pathVariables: {id: number, imageId: number}): Promise<Blob> {
        if (!this._getCompanyProfileGalleryImage) throw new Error("resource has not been properly initialized")
        return this._getCompanyProfileGalleryImage(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/company/{id}/companyProfile/heroImage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyProfileHeroImage: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getCompanyProfileHeroImage(id: number): Promise<Blob> {
        if (!this._getCompanyProfileHeroImage) throw new Error("resource has not been properly initialized")
        return this._getCompanyProfileHeroImage(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/consultant/{id}/picture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getConsultantProfilePicture: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getConsultantProfilePicture(id: number): Promise<Blob> {
        if (!this._getConsultantProfilePicture) throw new Error("resource has not been properly initialized")
        return this._getConsultantProfilePicture(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/landingPageJobs',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getJobsForLandingPage: IResourceMethodObservableStrict<null, null, null, PagedData<EmploymentPositionLandingPageData>> | undefined
    getJobsForLandingPage(): Promise<PagedData<EmploymentPositionLandingPageData>> {
        if (!this._getJobsForLandingPage) throw new Error("resource has not been properly initialized")
        return this._getJobsForLandingPage(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/positions/count',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMaxPossibleEmploymentPositionCount: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number>> | undefined
    getMaxPossibleEmploymentPositionCount(): Promise<JsonWrappedValue<number>> {
        if (!this._getMaxPossibleEmploymentPositionCount) throw new Error("resource has not been properly initialized")
        return this._getMaxPossibleEmploymentPositionCount(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/subjects/{id}/fieldOfActivities',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getProfessionalFieldOfActivitiesBySubject: IResourceMethodObservableStrict<null, null, {id: number}, LabelData[]> | undefined
    getProfessionalFieldOfActivitiesBySubject(id: number): Promise<LabelData[]> {
        if (!this._getProfessionalFieldOfActivitiesBySubject) throw new Error("resource has not been properly initialized")
        return this._getProfessionalFieldOfActivitiesBySubject(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/talentAgentLandingPage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentAgentLandingPage: IResourceMethodObservableStrict<null, null, null, PublicTalentTalentAgentLandingPageData> | undefined
    getTalentAgentLandingPage(): Promise<PublicTalentTalentAgentLandingPageData> {
        if (!this._getTalentAgentLandingPage) throw new Error("resource has not been properly initialized")
        return this._getTalentAgentLandingPage(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/topJobContents/{slotContentId}/headerImage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTopJobSlotHeaderImage: IResourceMethodObservableStrict<null, null, {slotContentId: number}, Blob> | undefined
    getTopJobSlotHeaderImage(slotContentId: number): Promise<Blob> {
        if (!this._getTopJobSlotHeaderImage) throw new Error("resource has not been properly initialized")
        return this._getTopJobSlotHeaderImage(null, null, {slotContentId: slotContentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/topJobContents/{slotContentId}/contactImage',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTopSlotContactImage: IResourceMethodObservableStrict<null, null, {slotContentId: number}, Blob> | undefined
    getTopSlotContactImage(slotContentId: number): Promise<Blob> {
        if (!this._getTopSlotContactImage) throw new Error("resource has not been properly initialized")
        return this._getTopSlotContactImage(null, null, {slotContentId: slotContentId}).toPromise()
    }

}